export const isBrowser = () => typeof window !== "undefined";


const easeOutQuint = x => 1 - Math.pow(1 - x, 5);

/**
 * 
 * @param {number} from 
 * @param {number} to 
 * @param {number} startTime 
 * @param {number} duration 
 */
export const scroll = (from, to, startTime, duration) => {
	const nowTime = performance.now();
	const progress = Math.floor(easeOutQuint((nowTime - startTime) / duration) * 10000) / 10000;
	const next = from - ((from - to) * progress);

	window.scrollTo(0, next);
	
	if (progress < 1) {
		requestAnimationFrame(() => scroll(from, to, startTime, duration));
	} else {
		window.scrollTo(0, to);
	}
};


export const sendForm = async ({ data, token }) => {

	const RECAPTCHA_THRESHOLD = 0.5;

	const USER = process.env.GATSBY_WORDPRESS_APPLICATION_USER;
	const PASSWORD = process.env.GATSBY_WORDPRESS_APPLICATION_PASSWORD;

	const apiRoot = `${process.env.GATSBY_WORDPRESS_HOST}/wp-json`;
	const authorization = `Basic ${btoa(`${USER}:${PASSWORD}`)}`;

	try {

		if (!token) {
			throw new Error("Token is not set");
		}

		const reCaptchaResponse = await fetch(`${apiRoot}/lp/v1/recaptcha`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ token }),
		}).then(res => res.json());


		if (!reCaptchaResponse.success) {
			throw new Error("reCAPTCHA failed");
		}

		if (reCaptchaResponse.score < RECAPTCHA_THRESHOLD) {
			throw new Error("reCAPTCHA score is low");
		}


		const res = await fetch(`${apiRoot}/wp/v2/contact`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": authorization,
			},
			body: JSON.stringify(data),
		});

		if (!res.ok) {
			throw new Error(res.statusText);
		}

		return await res.json();
		
	} catch (error) {

		throw new Error(error.message);
		
	}
};


/**
 * 
 * @param {string} htmlText 
 * @returns 
 */
export const transformHtmlText = (htmlText) => {

	if (!htmlText) {
		return { contents: null, headings: null };
	}

	const contents = htmlText
		.split("\n")
		.filter(line => line)
		.map((content, index) => {
			const matchHeading = content.match(/^<h([1-6])[^>]*>(.+)<\/h[1-6]>$/);
			if (matchHeading) {
				const headingText = matchHeading[2].replace(/<\/?[^>]+>/g, "");
				const level = matchHeading[1];

				// 見出しにid属性を追加する
				return `<h${level} id="${headingText}_${index}">${headingText}</h${level}>`;
			}
			return content;
		});

	const headings = contents
		.filter(content => content.match(/^<h[1-6].+h[1-6]>$/))
		.map(heading => {
			const matches = heading.match(/^<h([1-6]) id="(.+)"[^>]*>(.+)<\/h[1-6]>$/);
			if (!matches) {
				return null;
			}
			return {
				level: matches[1],
				id: matches[2],
				text: matches[3],
			};
		})
		.filter(h => h);

	return { contentsHtmlText: contents.join(""), headings };
};



/**
 * ゼロ埋め
 */
export const zeroPadding = (value, maxLength) => {
	return ("0".repeat(maxLength) + value).slice(-maxLength);
};



/**
 * 2桁のゼロ埋め
 */
export const zeroPadding2 = (value) => zeroPadding(value, 2);



/**
 * 4桁のゼロ埋め
 */
export const zeroPadding4 = (value) => zeroPadding(value, 4);